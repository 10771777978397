<template>
<div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
  <div id="page-content" v-if="!viLoader">
    <div class="webNew-dispflex"   >
       
       <!-- <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div> -->
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Online Admission Management
       </h4> <span style="color: red;margin-top: 6px;margin-left: 11px;"></span>
      </div>

    <!-- <div class="row mt-2">
           <div class="col-md-12" >
            
            <span class="hdrtitle ml-2" style="font-weight: normal;">Online Admission Management </span>
          <span style="color: red;">[ Launching soon in phase 2]</span>
          </div>
        </div> -->
    <!-- <b-spinner v-if="showToast" variant="primary" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner> -->
    <!-- <div class="box">
    <div class="rectangle">
  </div>
</div> -->
    <!-- <div class="row mt-2">
           
           
           
           <div class="col-md-4" >
              <div class="white-box m-2 white-box1" style="padding: 0;">
                <div class="row mt-2">
                  <div class="col-md-12" >
                  <p>
                

                <span class="veiw_all">
                  <a style="color: blue;text-align: end" >View All</a>
                </span>
              </p>
            </div >
                </div>
                <div class="row mt-2">
                   
                  </div>
                  <div class="row mt-2">
                   
                  </div>
              </div>
             
            </div>
            <div class="col-md-4" >
              <div class="white-box m-2 white-box1" style="padding: 0;">
               
              </div>
             
            </div> 
             <div class="col-md-4" >
              <div class="white-box m-2 white-box1" style="padding: 0;">
               
              </div>
             
            </div>
          </div> -->
        
          <div class="row mt-2">
           
           
           
           <div class="col-md-4" >
              <div
                class="white-box m-2 white-box1"
                style="padding: 0;"

                @click.prevent="redirectRoute('/admission/new')"
              >
                <div class="admcount_lft blu_bg" @click.prevent="redirectRoute('/admission/new')">
                  
                                <!-- @click.prevent="redirectRoute('/admission/new')" -->

                  <!-- <span class="steps">Step 1</span> -->
                  <span class="admcount org_bg">0</span
                  ><span style="display: block;line-height:18px">Manage Admissions</span>
                </div>
                <div class="admcount_rgt">
                  <div class="triangle-left"></div>
                  Manage Admissions process for the current year
                  <span>[the current annual period <br />2021 - 20]</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div
                class="white-box m-2 white-box1"
                style="padding: 0;"
                @click.prevent="redirectRoute('/admission/new/enquiry')"
              >
              <!-- @click.prevent="redirectRoute('/admission/admissionOnline')" -->
                <div class="admcount_lft blu_bg">
                  <span class="admcount org_bg">0</span
                  ><span  style="display: block;line-height:18px">Enquiry Tracker</span>
                </div>
                <div class="admcount_rgt">
                  <div class="triangle-left"></div>
                  Manage Enquiry Tracker
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div
                class="white-box m-2 white-box1"
                style="padding: 0;"
                @click.prevent="redirectRoute('/admission/admissionOnline')"
              >
              <!-- @click.prevent="redirectRoute('/admission/admissionOnline')" -->
                <div class="admcount_lft blu_bg">
                  <span class="admcount org_bg">0</span
                  ><span  style="display: block;line-height:18px">Admission Tracker</span>
                </div>
                <div class="admcount_rgt">
                  <div class="triangle-left"></div>
                  Manage Admission Tracker
                </div>
              </div>
            </div>
          </div>
  </div>
  <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
       
        </div>
</template>
<script>

import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";

export default {
  name: "vi-admission-home",
 
  data() {
    return {
      showToast: false,
      viLoader: false,
      isEnableBranch: false,
      count: {
        admissionNew: 0,
        onlineadmission: 0
      }
    };
  },
  created() {
    ////this.initComponent();
    //this.getAllObjectCounts();
  },
  computed: {
      styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      }
  },
  methods: {
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",data);
     this.$router.go(-1)
    },

    redirectRoute(vipath) {
      this.showToast = true
     // this.viLoader = true;
      if(this.$route.path !== vipath) {
        this.$router.push({ path: vipath })
      };
      this.showToast = false
     // this.viLoader = false;
    }
    
  },
   components: {
    ViSpinner
  },
};
</script>

<style lang="scss" scoped>
.box {
  height: 464px;
  width: 577px;
}

.box .rectangle {
  background-color: #ffffff;
  border: 0.69px solid;
  border-color: #c0c4e9;
  border-radius: 30.22px;
  box-shadow: 0px 0px 4.12px #00000040;
  height: 465px;
  left: 0;
  position: fixed;
  top: 0;
  width: 578px;
}
</style>