var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"Pgwrp",style:(_vm.styles),attrs:{"id":"Pgwrp"}},[(!_vm.viLoader)?_c('div',{attrs:{"id":"page-content"}},[_vm._m(0),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"white-box m-2 white-box1",staticStyle:{"padding":"0"},on:{"click":function($event){$event.preventDefault();return _vm.redirectRoute('/admission/new')}}},[_c('div',{staticClass:"admcount_lft blu_bg",on:{"click":function($event){$event.preventDefault();return _vm.redirectRoute('/admission/new')}}},[_c('span',{staticClass:"admcount org_bg"},[_vm._v("0")]),_c('span',{staticStyle:{"display":"block","line-height":"18px"}},[_vm._v("Manage Admissions")])]),_vm._m(1)])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"white-box m-2 white-box1",staticStyle:{"padding":"0"},on:{"click":function($event){$event.preventDefault();return _vm.redirectRoute('/admission/new/enquiry')}}},[_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"white-box m-2 white-box1",staticStyle:{"padding":"0"},on:{"click":function($event){$event.preventDefault();return _vm.redirectRoute('/admission/admissionOnline')}}},[_vm._m(4),_vm._m(5)])])])]):_vm._e(),(_vm.viLoader)?_c('vi-spinner',{staticClass:"flex-fill h-100 vi-fs12",staticStyle:{"width":"100%","z-index":"111","top":"0","left":"0","background-color":"rgba(255, 255, 255, 0.5)","border-radius":"1rem"},attrs:{"text":"Loading...","textColor":"vi-brand-color"}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"webNew-dispflex"},[_c('h4',{staticClass:"hdrtitle backHeading",staticStyle:{"margin-top":"0px"}},[_vm._v(" Online Admission Management ")]),_vm._v(" "),_c('span',{staticStyle:{"color":"red","margin-top":"6px","margin-left":"11px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admcount_rgt"},[_c('div',{staticClass:"triangle-left"}),_vm._v(" Manage Admissions process for the current year "),_c('span',[_vm._v("[the current annual period "),_c('br'),_vm._v("2021 - 20]")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admcount_lft blu_bg"},[_c('span',{staticClass:"admcount org_bg"},[_vm._v("0")]),_c('span',{staticStyle:{"display":"block","line-height":"18px"}},[_vm._v("Enquiry Tracker")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admcount_rgt"},[_c('div',{staticClass:"triangle-left"}),_vm._v(" Manage Enquiry Tracker ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admcount_lft blu_bg"},[_c('span',{staticClass:"admcount org_bg"},[_vm._v("0")]),_c('span',{staticStyle:{"display":"block","line-height":"18px"}},[_vm._v("Admission Tracker")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admcount_rgt"},[_c('div',{staticClass:"triangle-left"}),_vm._v(" Manage Admission Tracker ")])
}]

export { render, staticRenderFns }